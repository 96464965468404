html,
body {
  height    : 100%;
  width     : 100%;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

#root {
  height: inherit;
  width : inherit;
}

.app {
  height: inherit;
  width : inherit;
}

.cursor-pointer {
  cursor: pointer;
}

.my-container {
  margin : auto;
  width  : 100%;
  padding: 0 1rem;
}

.fade-animation {
  visibility: hidden;
  opacity   : 0;
  transition: visibility 0s 300ms, opacity 300ms linear;
}

.fade-animation-parent:hover>.fade-animation {
  visibility: visible;
  opacity   : 1;
  transition: opacity 300ms linear;
}

.content {
  padding-top: 4.5rem;
  height     : 100%;
}

.login {
  height         : 80%;
  display        : flex;
  align-items    : center;
  justify-content: center;
}

.boards-cards {
  display: grid;
  grid-template-columns:
    repeat(auto-fill, minmax(12rem, 1fr));
  gap: 1rem;
}

.boards-card {
  height      : 6rem;
  cursor      : pointer;
  position    : relative;
  touch-action: pan-x;
}

.boards-card__content {
  color           : white;
  background-color: rgba(0, 0, 0, 0.1);
}

.boards-card__title {
  display           : -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow          : hidden;
}

.boards-card__add {
  height         : 100%;
  display        : flex;
  justify-content: center;
  align-items    : center;
}

.boards-card__actions {
  background     : rgba(0, 0, 0, 0.1);
  position       : absolute;
  height         : 100%;
  width          : 100%;
  left           : 0;
  top            : 0;
  bottom         : 0;
  right          : 0;
  opacity        : 0;
  transition     : all 0.2s ease-in-out 0s;
  border-radius  : 0.25rem;
  color          : white;
  display        : flex;
  justify-content: flex-end;
  align-items    : flex-end;
  gap            : 0.5rem;
  padding        : 0.5rem;
}

.board {
  height        : 100%;
  display       : flex;
  flex-direction: column;
}

.board-title {
  min-height: 2rem;
}

.board-list {
  display         : flex;
  flex-direction  : column;
  background-color: #ebecf0;
  width           : 17rem;
  height          : fit-content;
  max-height      : calc(100% - 1rem);
  border-radius   : 0.25rem;
}

.boards-card__actions__icon,
.list-item__actions__icon {
  animation: transform 0.5;
  cursor   : pointer;
}

.boards-card__actions__icon:hover,
.list-item__actions__icon:hover {
  transform: scale(1.3);
}

.board-list__header {
  display        : flex;
  justify-content: space-between;
  align-items    : center;
  padding        : 0.5rem;
}

.list-cards {
  overflow  : auto;
  padding   : 0rem 0.5rem;
  min-height: 0.25rem;
}

.list-cards::-webkit-scrollbar-track {
  box-shadow      : inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius   : 10px;
  background-color: #F5F5F5;
}

.list-cards::-webkit-scrollbar {
  height          : 8px;
  width           : 8px;
  background-color: #F5F5F5;
}

.list-cards::-webkit-scrollbar-track-piece {
  background: #091e4214;
}

.list-cards::-webkit-scrollbar-thumb {
  border-radius   : 10px;
  box-shadow      : inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #091e4214;
  ;
}

.list-item__actions {
  display        : flex;
  justify-content: flex-end;
  gap            : 0.5rem;
  position       : absolute;
  height         : 100%;
  width          : 100%;
  padding        : 0.75rem 0.5rem;
}

.add-list {
  min-width: 17rem;
  height   : fit-content;
}

.toast-alert {
  z-index  : 9999;
  top      : 2rem;
  right    : 2rem;
  width    : 15rem;
  animation: slideInDown 500ms ease-in;
}

@keyframes slideInDown {
  from {
    transform: translateY(-500px);
  }

  to {
    transform: translateY(0);
  }
}